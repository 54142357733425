import { Button, ButtonProps } from "@redsift/design-system";
import styled from "styled-components";
import GithubSvg from "../../public/github.svg";
import GoogleSvg from "../../public/google.svg";
import EmailSvg from "../../public/mail.svg";
import MicrosoftSvg from "../../public/microsoft.svg";
import SAMLSvg from "../../public/saml.svg";

const StyledButton = styled(Button)`
  font-size: 13px;
  text-transform: none;
  background-color: #f7f7f7;
`;

const StyledEmailButton = styled(Button)`
  font-size: 13px;
  text-transform: none;
  height: 55px;

  > span {
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
  }
`;

export default function LoginButton({
  provider,
  isSignUp,
  ...props
}: { provider: any; isSignUp: boolean } & Omit<ButtonProps, "ref">) {
  let prefix = isSignUp ? "Sign up with " : "Sign in with ";
  let Svg, text;
  switch (provider) {
    case "google":
      Svg = GoogleSvg;
      text = prefix + "Google";
      break;
    case "microsoft":
      Svg = MicrosoftSvg;
      text = prefix + "Microsoft";
      break;
    case "saml":
      Svg = SAMLSvg;
      text = prefix + "SAML";
      break;
    case "github":
      Svg = GithubSvg;
      text = prefix + "Github";
      break;
    case "email":
      Svg = EmailSvg;
      text = prefix + "email";
      break;
    default:
      throw "Unsupported: " + provider;
  }

  if (provider === "email") {
    return (
      <StyledEmailButton
        {...props}
        variant="primary"
        leftIcon={<Svg />}
        width="224px"
        color="primary"
      >
        {text}
      </StyledEmailButton>
    );
  }

  return (
    <StyledButton
      {...props}
      variant="unstyled"
      leftIcon={<Svg />}
      width="224px"
      color="black"
      flex="1"
    >
      {text}
    </StyledButton>
  );
}
