import {
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
} from "firebase/auth";
import CONFIG from "../config/default.json";

export const HTTP_METHODS = {
  POST: "POST",
  GET: "GET",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
};

export const IAM_USERID_HEADER_NAME = "x-rs-iam-userid";
export const IAM_CLIENT_SECRET_HEADER_NAME = "x-rs-iam-clientsecret";

export const AUTH_COOKIE_NAME = "__rs-auth";

export const HTTP_DEFAULT_REQUEST_TIMEOUT: number =
  parseInt(process.env.HTTP_DEFAULT_REQUEST_TIMEOUT || "") || 5000;

export const EMAIL = {
  DISABLE_EMAIL_FLAG: !!(
    process.env.DISABLE_EMAIL_FLAG &&
    process.env.DISABLE_EMAIL_FLAG.toUpperCase() === "TRUE"
  ),
  FROM_EMAIL: { name: "Red Sift", email: "noreply@redsift.com" },
  SG_API_KEY: process.env.SG_API_KEY,
  TEMPLATES: {
    PASSWORD_RESET_EMAIL:
      process.env.PASSWORD_RESET_EMAIL || "d-aecf093410144d4cb19ab274a1e432cf",
    PASSWORD_UPDATED_EMAIL:
      process.env.PASSWORD_UPDATED_EMAIL ||
      "d-a2c54f25fb4846769d13d10a54e39da6",
    EMAIL_VERIFICATION_REQUESTED_EMAIL:
      process.env.EMAIL_VERIFICATION_REQUESTED_EMAIL ||
      "d-16b3a02da04d4b4dbf3b48bf9afdb32b",
    INVITATION_EMAIL: "d-e83f2e76bdba415684b854880cd4d143",
    PULSE_PROVISIONED_EMAIL:
      process.env.PULSE_PROVISIONED_EMAIL_TEMPLATE_ID ||
      "d-a98d872ec8604fa99d3703ed486ca405",
    PENDING_SIFT_PROVISIONED_EMAIL:
      process.env.PENDING_SIFT_PROVISIONED_EMAIL_TEMPLATE_ID ||
      "d-9064c7554dd843d2ac5a6fc1ce0a71cc",
  },
};

export const TEST_USER = {
  NAME: "Test User",
  EMAIL: "status@redsift.io",
};

export const BASE_URL = process.env.BASE_URL || "http://localhost:3000";

export const SPM = {
  ENDPOINT: process.env.SPM_ENDPOINT || "http://spm-rpc",
  NUM_RETRIES: process.env.SPM_NUM_RETRIES || 5,
  USE_HTTP_API: process.env.SPM_USE_HTTP_API || "true",
  NUM_GET_SIFTS_BATCH_SIZE: parseInt(
    process.env.SPM_NUM_GET_SIFTS_BATCH_SIZE || "20"
  ),
};

export const TILL = {
  ENDPOINT: process.env.TILL_ENDPOINT || "http://till",
};

export const RPC = {
  ENDPOINT: process.env.RPC_ENDPOINT || "http://rpc",
};

export const ERROR_MESSAGES = {
  SIFT_NOT_FOUND: "Sift not found",
  USER_NOT_FOUND: "User not found",
  APIKEY_NOT_FOUND: "Api Key not found",
  GROUP_NOT_FOUND: "Group not found",
  GROUP_USER_NOT_FOUND: "Group user not found",
  GROUP_APIKEY_NOT_FOUND: "Group Api Key not found",
  ORGANIZATION_NOT_FOUND: "Organization not found",
  DOMAIN_NOT_FOUND: "Domain not found",
  DOMAIN_NOT_LINKED_TO_ORG: "Domain not linked to organization",
  FORBIDDEN: "Forbidden",
  BAD_REQUEST: "Bad Request",
  INVITATION_NOT_FOUND: "Invitation not found",
  GENERIC: "The application has encountered an error",
};

export const ROOT_DOMAIN = process.env.KUBE_ROOT_DOMAIN || "redsift.cloud";
export const HOST_URL = ROOT_DOMAIN?.startsWith("localhost")
  ? `http://${ROOT_DOMAIN}`
  : `https://iam.${ROOT_DOMAIN}`;

export const APP_DOMAIN = `app.${ROOT_DOMAIN}`;

export const IAM_DOMAIN = `iam.${ROOT_DOMAIN}`;

export const NANO = {
  BENDER_BUCKET_VALUE: "_server",
  BENDER_KEY_SETTINGS: "account/settings",
};

export const API_KEY = {
  NUM_CHARACTERS_TO_KEEP: 4,
  SEPARATOR: "/",
};

const defaultGrantsStructure: any = {
  owner: {
    metadata: {
      "create:any": ["*"],
      "update:any": ["*"],
      "read:any": ["*"],
      "delete:any": ["*"],
    },
    users: {
      "create:any": ["*"],
      "update:any": ["*"],
      "read:any": ["*"],
      "delete:any": ["*"],
    },
    apiKeys: {
      "create:any": ["*"],
      "update:any": ["*"],
      "read:any": ["*"],
      "delete:any": ["*"],
    },
    groups: {
      "create:any": ["*"],
      "update:any": ["*"],
      "read:any": ["*"],
      "delete:any": ["*"],
    },
    billing: {
      "update:any": ["*"],
      "read:any": ["*"],
    },
    invitations: {
      "create:any": ["*"],
      "update:any": ["*"],
      "read:any": ["*"],
      "delete:any": ["*"],
    },
  },
  superadmin: {
    metadata: {
      "create:any": ["*"],
      "update:any": ["*"],
      "read:any": ["*"],
      "delete:any": ["*"],
    },
    users: {
      "create:any": ["!owner", "*"],
      "update:any": ["!owner", "*"],
      "read:any": ["*"],
      "delete:any": ["!owner", "*"],
    },
    apiKeys: {
      "create:any": ["*"],
      "read:any": ["*"],
      "delete:any": ["*"],
    },
    groups: {
      "create:any": ["*"],
      "update:any": ["*"],
      "read:any": ["*"],
      "delete:any": ["*"],
    },
    invitations: {
      "create:any": ["!owner", "*"],
      "update:any": ["!owner", "*"],
      "read:any": ["*"],
      "delete:any": ["!owner", "*"],
    },
  },
  superreader: {
    metadata: {
      "read:any": ["*"],
    },
    users: {
      "read:any": ["*"],
    },
    apiKeys: {
      "read:any": ["*"],
    },
    groups: {
      "read:any": ["*"],
    },
    invitations: {
      "read:any": ["*"],
    },
  },
  admin: {
    metadata: {
      "read:any": ["*"],
    },
    groups: {
      "read:own": ["*"],
    },
    users: {
      "read:own": ["*"],
    },
    apiKeys: {
      "read:own": ["*"],
    },
  },
  reader: {
    metadata: {
      "read:any": ["*"],
    },
    groups: {
      "read:own": ["*"],
    },
    apiKeys: {
      "read:own": ["*"],
    },
    users: {
      "read:own": ["*"],
    },
  },
  finance: {
    billing: {
      "update:any": ["*"],
      "read:any": ["*"],
    },
  },
  radarpoweruser: {
    metadata: {
      "read:any": ["*"],
    },
    users: {
      "read:any": ["*"],
    },
    apiKeys: {
      "read:any": ["*"],
    },
    groups: {
      "read:any": ["*"],
    },
    invitations: {
      "read:any": ["*"],
    },
  },
};

export const GROUP_ROLES = ["admin", "reader"];

export const INVITATION_STATUS = {
  DECLINED: "Declined",
  ACCEPTED: "Accepted",
  INVITED: "Invited",
};

export const PERMISSIONS = {
  OWNER: "owner",
  NONE: "none",
  RESOURCE_TYPES: ["domains"],
  SUPER_ROLES: [
    "owner",
    "superadmin",
    "superreader",
    "finance",
    "radarpoweruser",
  ],
  GRANTS_STRUCTURE: defaultGrantsStructure,
  RESOURCE_MAPPINGS_LIST: [
    {
      resource: "apiKeys",
      urlPath: "apiKeys",
      requestPath: "query.apiKeyId",
      siftPath: "apiKeys",
    },
    {
      resource: "users",
      urlPath: "users",
      requestPath: "query.siftUserId",
      siftPath: "users",
    },
    {
      resource: "groups",
      urlPath: "groups",
      requestPath: "query.groupId",
      siftPath: "groups",
    },
    {
      resource: "metadata",
      urlPath: "metadata",
      requestPath: "query.siftId",
      siftPath: "metadata",
    },
    {
      resource: "invitations",
      urlPath: "invitations",
      requestPath: "query.invitationId",
      siftPath: "invitations",
    },
  ],
  ARE_PERMISSIONS_ENABLED_DEFAULT_VALUE: true,
};

export const USER_TYPE = {
  API_KEY: "api_key",
  STANDALONE: "standalone",
};

export const METRICS = {
  TYPES: {
    COUNT: "count",
    HISTOGRAM: "histogram",
  },
  STATSD: {
    HOST: process.env.HOST,
    PORT: Number(process.env.STATSD_PORT),
  },
  PREFIX: "IAM.metrics.v1",
};

export const FEDERATED_LOGIN_PROVIDERS = (tenantId?: string) => [
  {
    value: "google",
    label: "Google",
    host: "google.com",
    get: () => new GoogleAuthProvider(),
    enableLogin: tenantId ? false : true,
    enableSignup: tenantId ? false : true,
  },
  {
    value: "microsoft",
    label: "Microsoft",
    host: "microsoft.com",
    get: () => {
      const p = new OAuthProvider("microsoft.com");
      p.addScope("User.Read");
      return p;
    },
    enableLogin: tenantId ? false : true,
    enableSignup: tenantId ? false : true,
  },
  {
    value: "github",
    label: "GitHub",
    host: "github.com",
    get: () => new GithubAuthProvider(),
    enableLogin: false,
    enableSignup: false,
  },
  {
    value: "saml",
    label: "SAML",
    host: `saml.${tenantId}`,
    get: () => new SAMLAuthProvider(`saml.${tenantId}`),
    enableLogin: tenantId ? true : false,
    enableSignup: tenantId ? true : false,
  },
];

export const APPS: {
  [key: string]: string;
} = {
  cloud: "cloud",
  default: "cloud",
};

// Sifts that can have a profile page
export const PROFILE_PAGES_CONFIG: any = {
  "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1": {
    name: "OnDMARC",
  },
  "1TpUvROtKGDhePFVJBYVINLJWGg1JDKWlizlAj0wmrruBq4N.1": {
    name: "OnINBOX Manager",
  },
  "o1aBPkOLYJn9f3ixUOrZ0qmUDjiS0SGbo1dDLz8DRrVUGvuU.1": {
    name: "Brand Trust",
  },
  // Sift admin
  "q7venKqTTl20ytgeT6XV2nZFbGiGSyHN2PpyIeiSm0GHngx8.1": {
    name: "Sift Admin",
  },
  // Partner tools
  "9x9BBXwV_SjsFMqu4y_MkhRFJHZ3zjXv5BdyqxCQF1U1H6Kx.1": {
    name: "Partner Toolbox",
  },
  // Manon
  "o0WjdJWXS4B9liBFNlS7sGrefDDTqPjZq3iGyVHIgIjNGkRJ.1": {
    name: "Manon",
  },
  // Sift trust
  "ojfvrL3W5JP7-m5sIuEl-FqKVwSdQ3KfWn6199yAUq3xnddu.1": {
    name: "Sift Trust",
  },
  // Sift Ourselves
  "kl8LGEWJkN4m8P6kgI3zBnZmRKp4vWW9BSNBXJ3O9urUMGSZ.1": {
    name: "Ourselves",
  },
  "Eo3vZZDB7FGslknmObc6aQlCjVfV9AoQghKR_Q8mf9SFZyfK.1": {
    name: "Vendor Secure",
  },
  "Ihug_f3sAzetn_RmmHcNXIVJEx-vy4FnIQ-bjat3DCf-fVER.1": {
    name: "Pulse",
  },
  "0Q-ZxmJp2HvJ8rRUDb9l5ze0dkEjT8LBnmNMDKMJCT8XfmFm.1": {
    name: "Certificates",
  },
};

export const SIFT_NAMES: any = {
  "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1": "OnDMARC",
  "INrw29d28M66gDWAcp522XHMTc1kFuxIlTdpoSLCn-drkCDg.1": "OnINBOX",
  "1TpUvROtKGDhePFVJBYVINLJWGg1JDKWlizlAj0wmrruBq4N.1": "OnINBOX Manager",
  "o1aBPkOLYJn9f3ixUOrZ0qmUDjiS0SGbo1dDLz8DRrVUGvuU.1": "Brand Trust",
  "q7venKqTTl20ytgeT6XV2nZFbGiGSyHN2PpyIeiSm0GHngx8.1": "Sift Admin",
  "9x9BBXwV_SjsFMqu4y_MkhRFJHZ3zjXv5BdyqxCQF1U1H6Kx.1": "Partner Toolbox",
  "o0WjdJWXS4B9liBFNlS7sGrefDDTqPjZq3iGyVHIgIjNGkRJ.1": "Manon",
  "kl8LGEWJkN4m8P6kgI3zBnZmRKp4vWW9BSNBXJ3O9urUMGSZ.1": "Ourselves",
  "ojfvrL3W5JP7-m5sIuEl-FqKVwSdQ3KfWn6199yAUq3xnddu.1": "Sift Trust",
  "N8i0a2WlU_KLgA-ey8ovovIJUHsC6vj9vPvg-NzJKY13YNIi.1": "Vendor Secure Agent",
  "Eo3vZZDB7FGslknmObc6aQlCjVfV9AoQghKR_Q8mf9SFZyfK.1": "Vendor Secure",
  "Ihug_f3sAzetn_RmmHcNXIVJEx-vy4FnIQ-bjat3DCf-fVER.1": "Pulse Platform",
  "0Q-ZxmJp2HvJ8rRUDb9l5ze0dkEjT8LBnmNMDKMJCT8XfmFm.1": "Certificates",
};

export const PROVIDERS = {
  GOOGLE: "google",
  MICROSOFT: "azure_active_directory",
  SLACK: "slack",
};

export const OAUTHD = {
  ENDPOINT: CONFIG.OAUTHD_ENDPOINT || `https://oauth.${ROOT_DOMAIN}`,
  SALT: process.env.OAUTHD_SALT,
};

export const ADMIN_API_PATH = "/api/admin/";
export const INVITATIONS_API_PATH = "/api/invitations";
export const ORGANIZATIONS_API_PATH = "/api/v2/organizations";
export const HARDENIZE_INVITATIONS_API_PATH = "/api/hardenizeinvitations";
export const HARDENIZE_BULK_INVITATIONS_API_PATH =
  "/api/hardenizeinvitations/bulk";

export const MFA = {
  ATTEMPTS_BEFORE_LOCKOUT: 5,
};

export const ONINBOX_OAUTHD_APP_KEY = process.env.ONINBOX_OAUTHD_APP_KEY || "";

export const ONINBOX_OAUTHD_APP_SECRET =
  process.env.ONINBOX_OAUTHD_APP_SECRET || "";

// Sifts that cannot be accessed via the admin portal
export const NO_ADMIN_SIFT = [
  "INrw29d28M66gDWAcp522XHMTc1kFuxIlTdpoSLCn-drkCDg.1",
  "1TpUvROtKGDhePFVJBYVINLJWGg1JDKWlizlAj0wmrruBq4N.1",
];

export const LOCK_TYPE = {
  SIFT_INSTALLATION: "SIFT INSTALLATION",
};

export const ORGANIZATION_TYPE = {
  MSSP: "MSSP",
  USER: "USER",
};

const ONINBOX_GUID = "INrw29d28M66gDWAcp522XHMTc1kFuxIlTdpoSLCn-drkCDg.1";
const VENDOR_SECURE_AGENT_GUID =
  "N8i0a2WlU_KLgA-ey8ovovIJUHsC6vj9vPvg-NzJKY13YNIi.1";

export const SINGLE_USER_GUIDS = [VENDOR_SECURE_AGENT_GUID, ONINBOX_GUID];

export const PULSE_GUID = "Ihug_f3sAzetn_RmmHcNXIVJEx-vy4FnIQ-bjat3DCf-fVER.1";

export const ONDMARC_SUBSCRIPTIONS_API_KEY =
  process.env.ONDMARC_SUBSCRIPTIONS_API_KEY || "";

export const MULTI_INSTANCE_LIMIT_PER_USER = 100;

export const HARDENIZE = {
  ENDPOINT: process.env.HARDENIZE_ENDPOINT || "https://staging.hardenize.net",
  NUM_RETRIES: Number(process.env.HARDENIZE_NUM_RETRIES) || 5,
  USERNAME:
    process.env.HARDENIZE_USERNAME || "hardenize-ops+redsift-system@redsift.io",
  PASSWORD: process.env.HARDENIZE_USER_PASSWORD || "",
  DEFAULT_ORG_STATUS: "active",
  DEFAULT_ORG_COMMERCIAL_STATUS: "internal",
};

export const AUDIT_LOGS_TYPES = {
  ADDITION: "addition",
  DELETION: "deletion",
  MODIFICATION: "modification",
  LOG_IN: "log-in",
  LOG_OUT: "log-out",
};

export const AUDIT_LOG_ACTIONS = {
  GROUPS: {
    ADDITION: "group-addition",
    DELETION: "group-deletion",
    MODIFICATION: "group-modification",
  },
  API_KEYS: {
    ADDITION: "api-key-addition",
    DELETION: "api-key-deletion",
  },
  INVITATIONS: {
    ADDITION: "invitation-addition",
    DELETION: "invitation-deletion",
    MODIFICATION: "invitation-modification",
  },
  USERS: {
    ADDITION: "user-addition",
    DELETION: "user-deletion",
    MODIFICATION: "user-modification",
  },
  USER: {
    LOG_IN: "user-login",
    LOG_OUT: "user-logout",
  },
};

export const AUDIT_LOG_RESOURCE_TYPE = {
  GROUP: "group",
  API_KEY: "api-key",
  INVITATIONS: "invitation",
  USER: "user",
};
